import React from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";
import useDarkMode from "use-dark-mode";

import styles from "./marquee-brands.css";

interface Props {
  double?: boolean;
  className?: string;
}

export const MarqueeBrands: React.FC<Props> = ({ double = false, className }) => {
  const {
    brands: { nodes, totalCount },
  } = useStaticQuery(graphql`
    query allMarqueeBrands {
      brands: allSanityBrand(sort: { fields: orderRank, order: ASC }) {
        nodes {
          id
          name
          image {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
        totalCount
      }
    }
  `);

  const darkMode = useDarkMode();

  const gradient: Pick<React.ComponentProps<typeof Marquee>, "gradientColor" | "gradientWidth"> = {
    gradientColor: darkMode.value ? [15, 8, 25] : [255, 255, 255],
    gradientWidth: 12,
  };

  if (double) {
    const perMarquee = Math.floor(totalCount / 2);

    return (
      <div className={className}>
        <Marquee {...gradient} className={styles.double} speed={25}>
          {nodes.slice(0, perMarquee).map(({ id, name, image }: any) =>
            image?.asset?.gatsbyImageData ? (
              <div key={id} className={styles.card}>
                <GatsbyImage alt={name} image={image.asset.gatsbyImageData} />
              </div>
            ) : null,
          )}
        </Marquee>

        <Marquee {...gradient} direction="right" speed={25}>
          {nodes.slice(perMarquee, totalCount).map(({ id, name, image }: any) => (
            <div key={id} className={styles.card}>
              <GatsbyImage alt={name} image={image.asset.gatsbyImageData} />
            </div>
          ))}
        </Marquee>
      </div>
    );
  }

  return (
    <Marquee {...gradient} className={className} speed={50}>
      {nodes.map(({ id, name, image }: any) => (
        <div key={id} className={styles.card}>
          <GatsbyImage alt={name} image={image.asset.gatsbyImageData} />
        </div>
      ))}
    </Marquee>
  );
};
