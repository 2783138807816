import React, { useEffect, useRef, useState } from "react";

import gsap from "gsap";

import useIntersectionObserver from "../hooks/use-intersection-observer";

interface Props {
  initial?: number;
  end: number;
  duration?: number;
}

export const Counter: React.FC<Props> = ({ initial = 0, end, duration = 0.6 }) => {
  const trigger = useRef<HTMLUListElement>(null);
  const isVisible = useIntersectionObserver(trigger);

  const [{ number }, setNumber] = useState({ number: initial });

  useEffect(() => {
    if (!isVisible) return;

    const target = { number: 0 };

    gsap.to(target, {
      duration,
      number: end,
      ease: "none",
      onUpdate: () => {
        setNumber({ number: target.number });
      },
    });
  }, [duration, end, initial, isVisible]);

  return <span ref={trigger}>{number.toFixed(0)}</span>;
};
