import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { Counter } from "../../ui/counter";
import styles from "./goals.css";

export const Goals = () => {
  const { goals } = useStaticQuery(graphql`
    query BlockAboutUsGoals {
      goals: sanityBlockAboutUs {
        count1 {
          number
          description
        }
        count2 {
          number
          description
        }
      }
    }
  `);

  return (
    <ul className={styles.count}>
      <li className={styles.countItem}>
        <Counter end={goals.count1.number} />{" "}
        <span className={styles.countDesc}>{goals.count1.description}</span>
      </li>
      <li className={styles.countItem}>
        <Counter end={goals.count2.number} />{" "}
        <span className={styles.countDesc}>{goals.count2.description}</span>
      </li>
    </ul>
  );
};
