import React from "react";

import { graphql, useStaticQuery } from "gatsby";

import { Container } from "../container";
import { Goals } from "../goals";
import { MarqueeBrands } from "../marquee-brands";
import { Title } from "../title";
import styles from "./block-experience.css";

export const BlockExperience = () => {
  const { block } = useStaticQuery(graphql`
    query BlockExperience {
      block: sanityBlockExperience {
        title
        description
      }
    }
  `);

  return (
    <Container className={styles.experienceContainer}>
      <div>
        <Title centerOnDesktop={false} subtitle={block.description} text={block.title} />
        <Goals />
      </div>

      <MarqueeBrands className={styles.experienceMarquee} double={true} />
    </Container>
  );
};
