import React from "react";

import cn from "clsx";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";

import styles from "./grid.css";

interface Item {
  id: string;
  image: IGatsbyImageData;
  title: string;
  description: string;
  button?: JSX.Element;
}

interface Props {
  items: Item[];
  columns?: 2 | 3;
}

export const Grid: React.FC<Props> = ({ items, columns = 2 }) => {
  return (
    <div className={cn(styles.container, columns === 3 && styles.threeColumns)}>
      {items.map(({ id, image, title, description, button }: Item) => (
        <div key={id} className={styles.wrap}>
          {image ? (
            <div className={styles.image}>
              <GatsbyImage alt={title} image={image} />
            </div>
          ) : null}
          <div className={styles.wrapText}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.description}>{description}</p>
            {button && <div className={styles.button}>{button}</div>}
          </div>
        </div>
      ))}
    </div>
  );
};
