import React from "react";

import { graphql, PageProps } from "gatsby";

import { BlockExperience } from "../components/block-experience";
import { Container } from "../components/container";
import { Grid } from "../components/grid";
import { LayoutPage } from "../components/layout-page";
import { Seo } from "../components/seo";
import { Title } from "../components/title";

export default function NosotrosPage({ data: { page } }: PageProps<any>) {
  return (
    <>
      <Seo
        description={page.seo.description}
        image={page.seo.image?.asset.gatsbyImageData.images.fallback.src}
        title={page.seo.title}
      />

      <LayoutPage
        heroButtonText={page.hero.button}
        heroDescription={page.hero.description}
        heroImage={page.hero.image.asset.gatsbyImageData}
        heroImageAlt="Nosotros"
        heroTitle={page.hero._rawTitle}
      >
        <Container variant="solid">
          <Title
            pretitle={page.sectionValuesHeader.pretitle}
            subtitle={page.sectionValuesHeader.subtitle}
            text={page.sectionValuesHeader.title}
          />

          <Grid
            items={page.sectionValuesItems.map(({ _key, title, description, image }: any) => ({
              id: _key,
              image: null,
              title,
              description,
            }))}
          />
        </Container>

        <BlockExperience />
      </LayoutPage>
    </>
  );
}

export const query = graphql`
  query {
    page: sanityPageUs {
      seo {
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, width: 1200, height: 630)
          }
        }
      }
      hero {
        _rawTitle
        description
        button
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 1050)
          }
        }
      }
      sectionValuesHeader {
        pretitle
        title
        subtitle
      }
      sectionValuesItems {
        _key
        title
        description
        image {
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 400)
          }
        }
      }
    }
  }
`;
