import React from "react";

import styles from "./image-blob.css";

interface Props {
  id: string;
  image: JSX.Element;
}

export const BlobImage: React.FC<Props> = ({ id, image }: any) => {
  const svgId = `svgClipPath-${id}`;

  return (
    <div className={styles.container}>
      <div style={{ clipPath: `url(#${svgId})` }}>{image}</div>

      <svg height={0} width={0}>
        <defs>
          <clipPath clipPathUnits="objectBoundingBox" id={svgId}>
            <path>
              <animate
                attributeName="d"
                dur="10000ms"
                repeatCount="indefinite"
                values={[
                  "M0.987,0.64 Q0.933,0.814,0.784,0.94 Q0.634,1,0.474,0.96 Q0.314,0.853,0.17,0.754 Q0.027,0.656,0.009,0.46 Q-0.009,0.265,0.146,0.16 Q0.302,0.056,0.458,0.019 Q0.615,-0.018,0.736,0.084 q0.121,0.102,0.213,0.242 q0.092,0.14,0.038,0.314",
                  "M0.969,0.653 Q0.879,0.827,0.724,0.943 Q0.569,1,0.405,0.969 Q0.241,0.879,0.115,0.767 Q-0.011,0.655,0.009,0.486 Q0.03,0.316,0.133,0.194 Q0.236,0.072,0.397,0.023 q0.161,-0.049,0.282,0.063 q0.121,0.112,0.251,0.252 q0.13,0.14,0.039,0.314",
                  "m0.977,0.682 q-0.022,0.173,-0.171,0.254 q-0.149,0.081,-0.319,0.067 Q0.318,0.99,0.157,0.864 Q-0.005,0.737,0.003,0.511 Q0.01,0.286,0.158,0.144 Q0.305,0.002,0.481,0.005 Q0.656,0.008,0.831,0.062 Q1,0.116,1,0.313 Q0.999,0.509,0.977,0.682",
                  "M0.987,0.64 Q0.933,0.814,0.784,0.94 Q0.634,1,0.474,0.96 Q0.314,0.853,0.17,0.754 Q0.027,0.656,0.009,0.46 Q-0.009,0.265,0.146,0.16 Q0.302,0.056,0.458,0.019 Q0.615,-0.018,0.736,0.084 q0.121,0.102,0.213,0.242 q0.092,0.14,0.038,0.314",
                ].join(";")}
              />
            </path>
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
