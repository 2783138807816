import { useEffect, useState } from "react";

interface Config {
  threshold?: number | number[] | undefined;
  root?: Element | Document | null | undefined;
  rootMargin?: string | undefined;
  freezeOnceVisible?: boolean;
}

const useIntersectionObserver = (
  elementRef: React.RefObject<HTMLDivElement | HTMLUListElement>,
  { threshold = 0, root = null, rootMargin = "0%", freezeOnceVisible = false }: Config = {},
): boolean => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const frozen = isVisible && freezeOnceVisible;

  const updateEntry = ([entry]: IntersectionObserverEntry[]) => {
    setIsVisible(entry?.isIntersecting);
  };

  useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport) return setIsVisible(true);

    if (frozen || !node) return;

    const observer = new IntersectionObserver(updateEntry, { threshold, root, rootMargin });

    observer.observe(node);

    return () => {
      observer.disconnect();
    };
  }, [elementRef, threshold, root, rootMargin, frozen]);

  return isVisible;
};

export default useIntersectionObserver;
